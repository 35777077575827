import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

import axios from "axios";

import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CRow,
  CContainer,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
} from "@coreui/react";
import { Button, Typography, Modal, notification } from "antd";
function EditSchoolType() {
  const [validated, setValidated] = useState(false);
  const [visible, setVisible] = useState(false);
  const token = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const location = useLocation();
  const id = location?.state?.catid;

  const [formData, setFormData] = useState({
    title: "",
    description: "",
  });

  const [errors, setErrors] = useState({});

  const fetchInitData = async (schoolId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/academics/school-type/${schoolId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data.data;
      setschoolType(data?.type)
//    console.log("0-0-0" , data , "9090909090" , response)
} catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchInitData(id);
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    // Clear error for the field being edited
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.title) {
      newErrors.title = "Title is required.";
    }
    if (!formData.description) {
      newErrors.description = "Description is required.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      setVisible(true);
    }
  };

  const handleFormSubmission = async (e) => {
    setVisible(false);
    e.preventDefault();
   

    try {
      await axios.patch(
        `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/academics/school-type/${id}`,
        {
         type:schoolType
        },
        {
          headers: {
             Authorization: `Bearer ${token}`,
          },
        }
      );
      notification.success({
        message: "Success",
        description: "Edited School Type  successfully!",
      });
      navigate("/school/school-type");
    } catch (error) {
      console.error("Error updating data:", error);
      alert("Failed to edit School Type!");
    }
  };
const [schoolType , setschoolType] = useState('')

  return (
    <>
      <CModal
        backdrop="static"
        visible={visible}
        onClose={() => setVisible(false)}
        aria-labelledby="StaticBackdropExampleLabel"
      >
        <CModalHeader>
          <CModalTitle id="StaticBackdropExampleLabel">
            Form Submission
          </CModalTitle>
        </CModalHeader>
        <CModalBody className="pb-3">
          Are you sure you wish to submit this form?
        </CModalBody>
        <CModalFooter className="pl-0">
          <button
            className="btn btn-dark ms-0 me-3"
            onClick={() => setVisible(false)}
          >
            Cancel
          </button>
          <CButton color="primary" onClick={handleFormSubmission}>
            Yes!
          </CButton>
        </CModalFooter>
      </CModal>
      <div className="Back_btn">
        <h2> Edit Allergies</h2>
        <button className="btn btn-primary" onClick={() => navigate(-1)}>
          Back
        </button>
      </div>

      {/* <div className="display-5 text-center pb-3 font-weight-bold">
        {" "}
        Allergies Information
      </div> */}
      <div className="h-100 d-flex justify-content-center align-items-center">
        <div className="w-50">
          {/* <div className="display-3 text-center pb-3">Edit Allergy</div>
        <div className="display-6 text-center pb-3">Allergy Information</div> */}

          <CForm
            className="needs-validation"
            noValidate
            onSubmit={handleSubmit}
          >
            <CRow className="mb-3 justify-content-center">
             <CCol md={6} className="d-flex flex-column align-items-center">
                             
                             <div className="w-100 pt-4">
                               <h4>School Type:</h4>
                               <div className=" pt-3">
                               
             
             <input
                                     className="form-control w-100"
                                     placeholder="Enter School Type here"
                                     
                                     type="text"
                                     value={schoolType}
                                     onChange={(e)=>setschoolType(e.target.value)}
                                     />
                                                   
                                 {/* <label htmlFor="floatingTextarea">
                                   Please enter School Type here
                                 </label> */}
                               </div>
                             </div>
                           </CCol>
            </CRow>
         

            <CRow className="text-center">
              <CCol md={12}>
                <CButton color="primary" type="submit">
                  Submit
                </CButton>
              </CCol>
            </CRow>
          </CForm>
        </div>
      </div>
    </>
  );
}

export default EditSchoolType;
