import React, { useState, useRef } from "react";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CFormLabel,
  CFormSelect,
  CFormCheck,
  CFormFeedback,
  CInputFile,
  CRow,
  CCardBody,
  CCard,
  CCardTitle,
  CContainer,
  CDateRangePicker,
  CSpinner,
  CToast,
  CToastHeader,
  CToastBody,
  CToaster,
} from "@coreui/react";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import { Button, Typography, Modal, notification } from "antd";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import sa from "date-fns/locale/ar-SA";
import moment from "moment";
import CIcon from "@coreui/icons-react";
import { cilLockLocked } from "@coreui/icons";
// imports for modal
import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
} from "@coreui/react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";

import axios from "axios";

import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

import { RotatingLines } from "react-loader-spinner";

const CustomInput = ({ value, onClick, labelName }) => (
  <CFormInput
    type="text"
    id="dateselect"
    placeholder="Aadat date"
    label={labelName}
    onClick={onClick}
    value={value}
  />
);

function EditSubject() {
  const academicYearId = localStorage.getItem("academicYearId");
  const [divs, setDivs] = useState([
    {
      stage: "",
      grade: "",
      subjects: [""],
    },
  ]);

  const handleChange = (index, field, value, subIndex = null, type = null) => {
    const updatedDivs = [...divs];
    if (type === "grade") {
      updatedDivs[index][type] = value;
    } else if (type === "subjects") {
      console.log("comingin subjects");
      updatedDivs[index][field][subIndex] = { name: null, id: value };
    } else if (type === "subjectstext") {
      console.log("comingin subjects text");

      updatedDivs[index][field][subIndex] = { name: value, id: null };
    } else if (field === "stage") {
      updatedDivs[index][field] = value;
    }
    setDivs(updatedDivs);
  };
  const addGrade = (index) => {
    const updatedDivs = [...divs];
    updatedDivs[index].grades.push("");
    setDivs(updatedDivs);
  };

  const addSubject = (index) => {
    const updatedDivs = [...subjects];
    updatedDivs.push({ name: "", id: null });
    setsubjects(updatedDivs);
  };

  const addNewDiv = (e) => {
    e.preventDefault();
    const lastDiv = divs[divs.length - 1];
    if (!lastDiv?.stage || !lastDiv?.grade) {
      notification.error({ message: "plese select all the fields" });
      return;
    }
    const isValid = lastDiv?.subjects.every((subject) => {
      const isNameFilled = subject.name !== null && subject.name !== "";
      const isIdFilled = subject.id !== null && subject.id !== "";

      // Ensure exactly one is filled
      return (isNameFilled && !isIdFilled) || (!isNameFilled && isIdFilled);
    });
    if (!isValid) {
      notification.warning({
        message: "Subject name field must have only one field filled",
      });
    }
    setDivs([
      ...divs,
      {
        stage: "",
        grade: "",
        subjects: [""],
      },
    ]);
  };
  const navigate = useNavigate();
  const location = useLocation();

  const id = location?.state?.catid;
  const [grade, setGrade] = useState("");
  const [stage, setStage] = useState("");
  const [subjects, setsubjects] = useState([]);
  const [catName, setcatName] = useState();
  const [categoryName, setcategoryName] = useState("");
  const [isLoding, setisLoding] = useState(false);
  const [getStartdate, setStartdate] = useState(null);
  const [getEndtdate, setEnddate] = useState(null);
  const [loading, setLoading] = useState(false);
  //alert(id);
  const [data, setData] = useState("");
  const token = localStorage.getItem("accessToken");
  const academic_Year = location?.state?.academicYear;
  const getUser = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/academics/grade-wise-subjects/${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setLoading(false);
      setStage(response?.data?.data?.stage?._id);
      setGrade(response?.data?.data?.grade?._id);
      setsubjects(response?.data?.data?.subjects);
      setDivs(response.data.data);
    } catch (error) {
      console.error("Error fetching users:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  console.log("subjects", subjects);
  useEffect(() => {
    getUser();
  }, []);
  console.log("divs val;ue is th", divs);
  const [toast, addToast] = useState(0);
  const toaster = useRef();

  const [formData, setFormData] = useState({
    name: "",
    category: "",
    responseType: [],
    customField: [
      {
        fieldTitle: "",
        fieldType: "",
        options: ["", ""],
      },
    ],
    isSurat: "",
    isRemark: "",
    isImageUpload: "",
    applicableTo: "",
    isCompulsory: "",
    startDate: new Date(),
    endDate: "",
    repetation: "",
    isImageUpload: "",
    repeatDays: [],
    customType: {
      cusresTitle: "",
      customFor: "",
      values: [{ value: "", customFor: "both" }],
    },
    repeatDays: [],
    repeatMonths: [],
    repeatDateForMonth: new Date(),
    repeatDateForYear: new Date(),
    customDate: new Date(),
    classes: [
      {
        stage: "",
        grade: "",
        section: "",
        grades: [],
        sections: [],
      },
    ],
  });

  const [showCustomOptions, setShowCustomOptions] = useState(false);
  const [pop1, setpop1] = useState(false);
  const [validated, setValidated] = useState(false);
  const [visible, setVisible] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      setVisible(true);
    }
  };

  useEffect(() => {
    setShowCustomOptions(formData.responseType.includes("custom"));
    setpop1(formData.responseType.includes("image"));
  }, [formData.responseType]);
  const handleFormSubmission = async () => {
    if (!subjects || subjects.length === 0) {
      notification.error("Plese Add Atleast One Subject.");
      return;
    }

    const subject = subjects?.[subjects.length - 1];

    if ("name" in subject) {
      const isValid =
        (!!subject?.name && !!!subject?.id) ||
        (!!!subject?.name && !!subject?.id);
      if (!isValid) {
        notification.warning({
          message: "warning",
          description:
            "Only one field must be filled, and the other must remain empty.",
        });
        return;
      }
    }

    const response = await axios.patch(
      `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/academics/grade-wise-subjects/${id}`,

      {
        academicYearId: academic_Year,
        data: {
          stage: stage,
          grade: grade,
          academicYearId: academic_Year,
          subjects: subjects.map((item) => {
            if (item._id) {
              const { _id, ...rest } = item;
              return { id: _id, ...rest };
            }

            return item;
          }),
        },
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    setVisible(false);
    navigate("/school/sections");
    notification.success({
      message: "Success",
      description: "Update  Subjects successfully!",
    });
  };

  const resetData = () => {
    setValidated(false);
    setFormData({
      name: "",
      category: "",
      responseType: [],
      customField: [
        {
          fieldTitle: "",
          fieldType: "",
          options: ["", ""],
        },
      ],
      isSurat: "",
      isRemark: "",
      isImageUpload: "",
      applicableTo: "",
      isCompulsory: "",
      startDate: new Date(),
      endDate: "",
      classes: [
        {
          stage: "",
          grade: "",
          section: "",
          grades: [],
          sections: [],
        },
      ],
      repetation: "",
      isImageUpload: "",
      repeatDays: [],
      customType: {
        cusresTitle: "",
        customFor: "",
        values: [""],
      },
      repeatDays: [],
      repeatMonths: [],
      repeatDateForMonth: new Date(),
      repeatDateForYear: new Date(),
      customDate: new Date(),
    });
  };

  //   getting categories
  const [getCats, setgetCats] = useState([]);

  // useEffect(() => {
  //   const getCats = async () => {
  //     try {
  //       const response = await axios.get(
  //         `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/categories`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       );

  //       setgetCats(response.data.data.docs);
  //     } catch (error) {
  //       console.error("Error fetching users:", error);
  //     }
  //   };
  //   getCats();
  // }, [id]);
  // const [getCats, setgetCats] = useState([]);

  useEffect(() => {
    const getCats = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/academics/get-all-subjects`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setgetCats(response.data.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    getCats();
  }, []);
  const [allStages, setAllStages] = useState([]);
  const [allGrades, setAllGrades] = useState([]);
  const [allSections, setAllSections] = useState([]);
  const deleteDiv = (e, value) => {
    e.preventDefault();
    if (subjects.length <= 1) {
      notification.error({
        message: "error",
        description: "Add Atleast One Subject",
      });
      return;
    }
    const lastDiv = subjects.filter((_, index) => index !== value);
    setsubjects(lastDiv);
  };
  const getStages = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/stage-grade-section/stage`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAllStages(res?.data?.records);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStages();
  }, []);
  const getGrades = async (stageId, initial = false) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/stage-grade-section/stage/${stageId}/grade`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setAllGrades(response?.data?.records);
    } catch (error) {
      console.error("Error fetching grades:", error);
    }
    return [];
  };
  const getSections = async (stageId, gradeId, initial = false) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/stage-grade-section/stage/${stageId}/grade/${gradeId}/section`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response?.statusText === "OK") {
        return initial ? response.data.records : null;
      }
    } catch (error) {
      console.error("Error fetching sections:", error);
    }
    return [];
  };
  const handleChangesubject = (index, value, comingFrom) => {
    const updatedSubject = [...subjects];
    if (comingFrom === "text") {
      updatedSubject[index] = { ...updatedSubject[index], name: value };
    } else updatedSubject[index] = { ...updatedSubject[index], id: value };
    setsubjects(updatedSubject);
  };
  useEffect(() => {
    if (divs) getGrades(divs?.stage?._id);
  }, [divs]);

  console.log("subjects", subjects);
  return (
    <>
      {isLoding ? <CSpinner /> : null}
      <CContainer fluid className="container overflow-auto">
        <CToaster
          className="p-3"
          placement="top-end"
          push={toast}
          ref={toaster}
        />
        {/* modal */}
        <CModal
          backdrop="static"
          visible={visible}
          onClose={() => setVisible(false)}
          aria-labelledby="StaticBackdropExampleLabel"
        >
          <CModalHeader>
            <CModalTitle id="StaticBackdropExampleLabel">
              Form Submission
            </CModalTitle>
          </CModalHeader>
          <CModalBody className="pb-3">
            Are you sure you wish to submit this form?
          </CModalBody>
          <CModalFooter className="pl-0">
            <button
              className="btn btn-dark ms-0 me-3"
              onClick={() => setVisible(false)}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary ms-2 "
              onClick={handleFormSubmission}
            >
              Yes!
            </button>
          </CModalFooter>
        </CModal>
        <div className="Back_btn">
          <h2>Edit Subjects</h2>
          <button className="btn btn-primary" onClick={() => navigate(-1)}>
            Back
          </button>
        </div>

        <CContainer fluid>
          {loading ? (
            <div className="spinner-container">
              <RotatingLines
                visible={true}
                height="96"
                width="96"
                strokeColor="#506EE4"
                strokeWidth="5"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </div>
          ) : (
            <CForm
              className="row mt-5 needs-validation"
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
            >
              <CRow className="justify-content-center pl-5 pr-5">
                <CCol md={8} className="">
                  <div className=" mt-3 ">
                    <div className="">
                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <div
                          className="w-80 d-flex flex-column gap-3 mb-3 border border-2 rounded p-4"
                          // key={index}
                        >
                          {
                            <div className="d-flex justify-content-end">
                              {/* <RemoveCircleIcon onClick={deleteDiv} color="error" style={{ cursor: "pointer" , float:"end" }} /> */}
                            </div>
                          }{" "}
                          <div>
                            <CFormSelect
                              label="Level"
                              name="category"
                              required
                              value={stage}
                              onChange={(e) => {
                                setStage(e.target.value);
                              }}
                              className="form-select"
                            >
                              <option selected disabled value="">
                                Choose...
                              </option>
                              {allStages &&
                                allStages.map((cat) => (
                                  <option key={cat._id} value={cat._id}>
                                    {cat.stage}
                                  </option>
                                ))}
                            </CFormSelect>
                          </div>
                          <div>
                            <label className="form-label">Grades</label>

                            <div className="d-flex align-items-center mb-2 gap-2">
                              <CFormSelect
                                required
                                value={grade}
                                onChange={(e) => setGrade(e.target.value)}
                                className="form-select flex-grow-1"
                              >
                                <option selected disabled value="">
                                  Choose...
                                </option>
                                {allGrades &&
                                  allGrades.map((cat) => (
                                    <option key={cat._id} value={cat._id}>
                                      {cat.grade}
                                    </option>
                                  ))}
                              </CFormSelect>
                            </div>
                          </div>
                          <div>
                            <div className="d-flex justify-content-between align-items-center">
                              <label className="form-label">Subjects</label>
                              <AddCircleOutlinedIcon
                                color="primary"
                                style={{
                                  cursor: "pointer",
                                  marginLeft: "10px",
                                }}
                                onClick={() => {
                                  const isValid =
                                    (subjects[subjects.length - 1]?.name &&
                                      !subjects[subjects.length - 1]?.id) ||
                                    (!subjects[subjects.length - 1]?.name &&
                                      subjects[subjects.length - 1]?.id);

                                  if (
                                    "name" in subjects[subjects.length - 1] &&
                                    !isValid
                                  ) {
                                    notification.warning({
                                      message: "warning",
                                      description:
                                        "Must have one field should be filled",
                                    });
                                  } else {
                                    addSubject();
                                  }
                                }}
                              />
                            </div>

                            {subjects &&
                              subjects.map((subject, subjectIndex) => (
                                <div
                                  key={subjectIndex}
                                  className="d-flex align-items-center mb-2 gap-2"
                                >
                                  <CFormSelect
                                    value={subject._id}
                                    onChange={(e) =>
                                      handleChangesubject(
                                        subjectIndex,

                                        e.target.value,

                                        ""
                                      )
                                    }
                                    className="form-select flex-grow-1"
                                  >
                                    {console.log("subjcts", getCats)}
                                    <option selected disabled value="">
                                      Choose...
                                    </option>
                                    {getCats.map((cat) => (
                                      <option key={cat._id} value={cat._id}>
                                        {cat.subject}
                                      </option>
                                    ))}
                                  </CFormSelect>
                                  {"name" in subject && (
                                    <>
                                      <h6>OR</h6>
                                      <input
                                        style={{ width: "200px" }}
                                        className="form-control"
                                        type="text"
                                        placeholder="Enter Subject Name"
                                        value={subject.name}
                                        onChange={(e) =>
                                          handleChangesubject(
                                            subjectIndex,

                                            e.target.value,

                                            "text"
                                          )
                                        }
                                      />
                                    </>
                                  )}

                                  <RemoveCircleIcon
                                    onClick={(e) => deleteDiv(e, subjectIndex)}
                                    color="error"
                                    style={{ cursor: "pointer", float: "end" }}
                                  />
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CCol>
              </CRow>
              <div className="buttons text-center pt-3">
                <CButton color="primary" type="submit">
                  Submit form
                </CButton>

                <CButton color="dark" type="reset" onClick={resetData}>
                  Reset form
                </CButton>
              </div>
            </CForm>
          )}
        </CContainer>
      </CContainer>
    </>
  );
}

export default EditSubject;
