import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import InstallPWAButton from "../../installbutton";
import { AuthContext } from "../../helpers/AuthContext";
import { useContext } from "react";
const SchoolAdminLoginTemp = () => {
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [logimg, setLogoImg] = useState("");
  const navigate = useNavigate();
  const { setAuthState } = useContext(AuthContext);

  const togglePasswordVisibility = () => {
    setPasswordVisible((prevState) => !prevState);
  };
  const location = useLocation();

  useEffect(() => {
    localStorage.setItem("menuOpened", "Dashboard");
  }, []);
  const date = () => {
    return new Date().getFullYear();
  };

  useEffect(() => {
    // Extract the school name and ID from the URL
    const currentPath = location.pathname;

    const pathParts = currentPath.split("/"); // Split the URL by '/'
    const schoolName = pathParts[3]; // Extract the school name (5th part of the path)
    const uniqueId = pathParts[4]; // Extract the school ID (6th part of the path)
    const imgurl = `https://api.myaadat.com/api/v1/school/get-image?uniqueId=${uniqueId}`;
    setLogoImg(imgurl);
    if (schoolName && uniqueId) {
      // Check if the current values are different from localStorage
      if (
        schoolName !== localStorage.getItem("schoolName") ||
        uniqueId !== localStorage.getItem("uniqueId")
      ) {
        // Store the school name and ID in localStorage
        localStorage.setItem("schoolName", schoolName);
        localStorage.setItem("uniqueId", uniqueId);
        console.log(`Stored School Name: ${schoolName}, ID: ${uniqueId}`);
      }
    }
  }, [location.pathname]);

  const handleLogin = async (e) => {
    e.preventDefault();

    // var uniqueId = `${process.env.REACT_APP_SCHOOL_ID}`;
    // console.log(uniqueId);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/school/login`,
        {
          email,
          password,
          uniqueId: localStorage.getItem("uniqueId"),
          role: "school-admin",
        }
      );
      console.log(response.data.data.user._id, "dD");

      setAuthState({
        role: response.data.data.user.role,
        id: response.data.data.user._id,
        name: response.data.data.user.firstName,
        lastname: response?.data?.data?.user?.lastName,
        academicYearId: "",
      });
      const token = response?.data?.token;
      const user = response.data?.data?.user?.role;
      //const user = "school"; // Hardcoded Role for now
      console.log(user);
      const userId = response?.data?.data?.user?._id;

      const schoolId = response.data?.data?.user?.schoolId;

      if (
        response?.data?.data?.user?.currentAcademicYear[0]?.is_current_year ==
        true
      ) {
        const academicYearId =
          response.data?.data?.user?.currentAcademicYear[0]?._id;
        localStorage.setItem("academicYearId", academicYearId);
      }

      if (
        response?.data?.data?.user?.nextAcademicYear[0]?.is_next_year == true
      ) {
        const academicYearId2 =
          response?.data?.data?.user?.nextAcademicYear[0]?._id;
        localStorage.setItem("upcomingyear", academicYearId2);
      }
      console.log(response);
      localStorage.setItem("schoolId", schoolId);
      localStorage.setItem("accessToken", token);

      //alert(token);
      localStorage.setItem("user", user);
      localStorage.setItem("userId", userId);
      // localStorage.setItem("schoolId", schoolId);
      console.log("sucessfully logged in");
      // window.location.replace("/");
      navigate("*");
      setEmail("");
      setPassword("");
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.message;
        console.error("Login failed:", errorMessage);
        alert(
          "Invalid URL. Please provide a valid school name and a unique ID to proceed."
        );

        // Show the error message from the backend
      } else {
        console.error("Login failed:", error);
        alert(error.message); // Show a generic error message
      }
    }
  };

  const accessToken = localStorage.getItem("accessToken");
  const usertype = localStorage.getItem("user");

  // useEffect(() => {
  //   if (usertype == "school-admin") {
  //     navigate("/school/dashboard");
  //   }
  // }, [usertype, navigate]);

  return (
    <div className="container-fuild">
      <div className="w-100 overflow-hidden position-relative flex-wrap d-block vh-100">
        <div className="row">
          <div className="col-lg-6 school_admin">
            <div className="d-lg-flex align-items-center justify-content-center d-lg-block d-none flex-wrap vh-100 overflowy-auto">
              <div className="authen-overlay-item w-75 mx-auto p-4">
                {" "}
                <h1 className="Admin_color">Welcome School Admin</h1>
                {/* Reduced width and centered */}
                {/* <Link to="/"  */}
                {/* <Link
                  to={`/student/login/${localStorage.getItem("schoolId")}`}
                  className="text-decoration-none"
                  tabIndex={-1}
                >
                  <div className="d-flex align-items-center flex-row mb-3 justify-content-between p-3 br-5 gap-3 card shadow-sm">
                    <div className="text-center w-100">
                      <span className="font-weight-bold">Login as Student</span>
                    </div>
                    <i className="ti ti-chevrons-right" />
                  </div>
                </Link>
                <Link
                  to="/admin"
                  className="text-decoration-none"
                  tabIndex={-1}
                >
                  <div className="d-flex align-items-center flex-row mb-3 justify-content-between p-3 br-5 gap-3 card shadow-sm">
                    <div className="text-center w-100">
                      <span className="font-weight-bold">Login as Admin</span>
                    </div>
                    <i className="ti ti-chevrons-right" />
                  </div>
                </Link> */}
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="row justify-content-center align-items-center vh-100 overflow-auto flex-wrap ">
              <div className="col-md-8 mx-auto p-4">
                {/* <InstallPWAButton /> */}
                <form onSubmit={handleLogin}>
                  <div>
                    <div className="card">
                      <div className="card-body pb-3">
                        <div className=" mb-4">
                          <div className="img_logo d-none">
                            <img
                              src={logimg}
                              crossorigin={process.env.REACT_APP_DEV_BASE_URL}
                            />
                          </div>
                          <h2 className="mb-2">
                            Welcome
                            <span className="d-none mobile_block">
                              School-Admin
                            </span>{" "}
                          </h2>
                          <p className="mb-0">
                            Please enter your details to sign in as School-Admin
                          </p>
                        </div>

                        <div className="mb-3 ">
                          <label className="form-label">Email</label>
                          <div className="input-icon mb-3 position-relative">
                            <span className="input-icon-addon">
                              <i className="ti ti-mail" />
                            </span>
                            <input
                              type="text"
                              defaultValue={email}
                              className="form-control"
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                          <label className="form-label">Password</label>
                          <div className="pass-group">
                            <input
                              type={isPasswordVisible ? "text" : "password"}
                              className="pass-input form-control"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            <span
                              className={`ti toggle-password ${
                                isPasswordVisible ? "ti-eye" : "ti-eye-off"
                              }`}
                              onClick={togglePasswordVisibility}
                            />
                          </div>
                        </div>
                        <div className="form-wrap form-wrap-checkbox">
                          <div className="d-flex align-items-center"></div>
                          <div className="text-end ">
                            <Link
                              to="/school/forgotpassword"
                              className="link-danger"
                            >
                              Forgot Password?
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="p-4 pt-0">
                        <div className="mb-3">
                          <button
                            type="submit"
                            className="btn btn-primary w-100"
                          >
                            Sign In
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SchoolAdminLoginTemp;
