import { configureStore } from "@reduxjs/toolkit";
import themeSettingSlice from "./themeSettingSlice";
import sidebarSlice from "./sidebarSlice";
import timetableSlice from "./timetableSlice";

const store = configureStore({
  reducer: {
    themeSetting: themeSettingSlice,
    sidebarSlice: sidebarSlice,
    timetableSlice: timetableSlice,
  },
});

export default store;
