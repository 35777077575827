import React, { useState, useRef } from "react";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CFormLabel,
  CFormSelect,
  CFormCheck,
  CFormFeedback,
  CInputFile,
  CRow,
  CCardBody,
  CCard,
  CCardTitle,
  CContainer,
  CDateRangePicker,
  CSpinner,
  CToast,
  CToastHeader,
  CToastBody,
  CToaster,
} from "@coreui/react";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import { Button, Typography, Modal, notification } from "antd";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import sa from "date-fns/locale/ar-SA";
import moment from "moment";
import CIcon from "@coreui/icons-react";
import { cilLockLocked } from "@coreui/icons";
// imports for modal
import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
} from "@coreui/react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";

import axios from "axios";

import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

import { RotatingLines } from "react-loader-spinner";
import { studentAttendance } from "../../../data/json/student_attendance";

const CustomInput = ({ value, onClick, labelName }) => (
  <CFormInput
    type="text"
    id="dateselect"
    placeholder="Aadat date"
    label={labelName}
    onClick={onClick}
    value={value}
  />
);

function AddSubject() {
  const academicYearId = localStorage.getItem("academicYearId");

  const [divs, setDivs] = useState([
    {
      stage: "",
      grade: "",
      subjects: [""],
    },
  ]);

  const handleChange = (index, field, value, subIndex = null, type = null) => {
    const updatedDivs = [...divs];
    if (type === "grade") {
      updatedDivs[index][type] = value;
    } else if (type === "subjects") {
      console.log("comingin subjects");
      updatedDivs[index][field][subIndex] = {
        ...updatedDivs[index][field][subIndex],
        id: value,
      };
    } else if (type === "subjectstext") {
      console.log("comingin subjects text");
      updatedDivs[index][field][subIndex] = {
        ...updatedDivs[index][field][subIndex],
        name: value,
      };
    } else if (field === "stage") {
      updatedDivs[index][field] = value;
    }
    setDivs(updatedDivs);
  };
  const addGrade = (index) => {
    const updatedDivs = [...divs];
    updatedDivs[index].grades.push("");
    setDivs(updatedDivs);
  };

  const addSubject = (index) => {
    const updatedDivs = [...divs];
    updatedDivs[index].subjects.push("");
    setDivs(updatedDivs);
  };

  const deleteDiv = (e) => {
    e.preventDefault();
    const lastDiv = divs.slice(0, divs.length - 1);
    setDivs(lastDiv);
  };
  const addNewDiv = (e) => {
    e.preventDefault();
    const lastDivSubjects = divs?.[divs.length - 1]?.subjects;
    const subject = lastDivSubjects?.[lastDivSubjects.length - 1];

    const isValid =
      (!!subject?.name && !!!subject?.id) ||
      (!!!subject?.name && !!subject?.id);
    if (!isValid) {
      notification.warning({
        message: "warning",
        description:
          "Only one Subject field must be filled, and the other must remain empty.",
      });
      return;
    }

    setDivs([
      ...divs,
      {
        stage: "",
        grade: "",
        subjects: [""],
      },
    ]);
  };

  const navigate = useNavigate();
  const location = useLocation();

  const id = location?.state?.catid;
  const academic_Year = location?.state?.academicYear;
  const [catName, setcatName] = useState();
  const [categoryName, setcategoryName] = useState("");
  const [isLoding, setisLoding] = useState(false);
  const [getStartdate, setStartdate] = useState(null);
  const [getEndtdate, setEnddate] = useState(null);
  const [loading, setLoading] = useState(false);
  //alert(id);

  const token = localStorage.getItem("accessToken");
  const getUser = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/aadat/${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const editStudent = response.data.data;
      setLoading(false);
      if (editStudent) {
        setFormData(editStudent);
        if (editStudent?.classes) {
          // If classes exist, fetch grades and sections for each class entry
          if (editStudent?.classes?.length > 0) {
            const updatedClasses = await Promise.all(
              editStudent.classes.map(async (classItem) => {
                const grades = await getGrades(classItem.stage, true); // Fetch grades for stage
                const sections = await getSections(
                  classItem.stage,
                  classItem.grade,
                  true
                ); // Fetch sections for grade
                return {
                  // Add fetched sections
                  stage: classItem?.stage,
                  grade: classItem.grade,
                  section: classItem?.section,
                  grades: grades,
                  sections: sections,
                };
              })
            );

            // Update formData with the fully populated classes array
            setFormData((prevData) => ({
              ...prevData,
              classes: updatedClasses,
            }));
          }
        }

        if (editStudent.startDate) {
          //   const startDate = new Date(editStudent.startDate);
          const startDate = moment(editStudent?.startDate).format("YYYY-MM-DD");
          setStartdate(startDate); // Set local date state
          setFormData((prev) => ({ ...prev, startDate: startDate })); // Update formData
        }
        if (editStudent.endDate) {
          //   const endDate = new Date(editStudent.endDate);
          const endDate = moment(editStudent?.endDate).format("YYYY-MM-DD");

          setEnddate(endDate); // Set local date state
          setFormData((prev) => ({ ...prev, endDate: endDate })); // Update formData
        }
      }
    } catch (error) {
      console.error("Error fetching users:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  // useEffect(() => {
  //   getUser();
  // }, []);

  const [toast, addToast] = useState(0);
  const toaster = useRef();

  const [formData, setFormData] = useState({
    name: "",
    category: "",
    responseType: [],
    customField: [
      {
        fieldTitle: "",
        fieldType: "",
        options: ["", ""],
      },
    ],
    isSurat: "",
    isRemark: "",
    isImageUpload: "",
    applicableTo: "",
    isCompulsory: "",
    startDate: new Date(),
    endDate: "",
    repetation: "",
    isImageUpload: "",
    repeatDays: [],
    customType: {
      cusresTitle: "",
      customFor: "",
      values: [{ value: "", customFor: "both" }],
    },
    repeatDays: [],
    repeatMonths: [],
    repeatDateForMonth: new Date(),
    repeatDateForYear: new Date(),
    customDate: new Date(),
    classes: [
      {
        stage: "",
        grade: "",
        section: "",
        grades: [],
        sections: [],
      },
    ],
  });

  const [showCustomOptions, setShowCustomOptions] = useState(false);
  const [pop1, setpop1] = useState(false);
  const [validated, setValidated] = useState(false);
  const [visible, setVisible] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      console.log("form is valid and good to ship");

      // asking for confirmation here
      setVisible(true);
    }
  };

  useEffect(() => {
    setShowCustomOptions(formData.responseType.includes("custom"));
    setpop1(formData.responseType.includes("image"));
  }, [formData.responseType]);
  const handleFormSubmission = async () => {
    const lastDivSubjects = divs?.[divs.length - 1]?.subjects;
    const subject = lastDivSubjects?.[lastDivSubjects.length - 1];

    const isValid =
      (!!subject?.name && !!!subject?.id) ||
      (!!!subject?.name && !!subject?.id);
    if (!isValid) {
      notification.warning({
        message: "warning",
        description:
          "Only one field must be filled, and the other must remain empty.",
      });
      return;
    }
    const requestBody = {
      academicYearId: academic_Year,
      data: divs.map((item) => ({
        stage: item.stage || null, // Assign null if stage is empty
        grade: item.grade || null, // Assign null if grade is empty
        subjects: item.subjects,
      })),
    };

    const response = await axios.post(
      `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/academics/grade-wise-subjects`,

      requestBody,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    setVisible(false);
    navigate("/school/sections");
    notification.success({
      message: "Success",
      description: "Add Subject successfully!",
    });
  };

  const resetData = () => {
    setValidated(false);
    setFormData({
      name: "",
      category: "",
      responseType: [],
      customField: [
        {
          fieldTitle: "",
          fieldType: "",
          options: ["", ""],
        },
      ],
      isSurat: "",
      isRemark: "",
      isImageUpload: "",
      applicableTo: "",
      isCompulsory: "",
      startDate: new Date(),
      endDate: "",
      classes: [
        {
          stage: "",
          grade: "",
          section: "",
          grades: [],
          sections: [],
        },
      ],
      repetation: "",
      isImageUpload: "",
      repeatDays: [],
      customType: {
        cusresTitle: "",
        customFor: "",
        values: [""],
      },
      repeatDays: [],
      repeatMonths: [],
      repeatDateForMonth: new Date(),
      repeatDateForYear: new Date(),
      customDate: new Date(),
    });
  };

  //   getting categories
  const [getCats, setgetCats] = useState([]);

  useEffect(() => {
    const getCats = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/academics/get-all-subjects`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log(response);
        setgetCats(response.data.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    getCats();
  }, []);

  const [allStages, setAllStages] = useState([]);
  const [allGrades, setAllGrades] = useState([]);
  const [allSections, setAllSections] = useState([]);

  const getStages = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/stage-grade-section/stage`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAllStages(res?.data?.records);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStages();
  }, []);
  const getGrades = async (stageId, initial = false) => {
    setLoading(true);
    setAllGrades([]);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/stage-grade-section/stage/${stageId}/grade`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setAllGrades(response?.data?.records);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching grades:", error);
    }
    return [];
  };

  const getSections = async (stageId, gradeId, initial = false) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/stage-grade-section/stage/${stageId}/grade/${gradeId}/section`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response?.statusText === "OK") {
        return initial ? response.data.records : null;
      }
    } catch (error) {
      console.error("Error fetching sections:", error);
    }
    return [];
  };
  return (
    <>
      {isLoding ? <CSpinner /> : null}
      <CContainer fluid className="container overflow-auto">
        <CToaster
          className="p-3"
          placement="top-end"
          push={toast}
          ref={toaster}
        />
        {/* modal */}
        <CModal
          backdrop="static"
          visible={visible}
          onClose={() => setVisible(false)}
          aria-labelledby="StaticBackdropExampleLabel"
        >
          <CModalHeader>
            <CModalTitle id="StaticBackdropExampleLabel">
              Form Submission
            </CModalTitle>
          </CModalHeader>
          <CModalBody className="pb-3">
            Are you sure you wish to submit this form?
          </CModalBody>
          <CModalFooter className="pl-0">
            <button
              className="btn btn-dark ms-0 me-3"
              onClick={() => setVisible(false)}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary ms-2 "
              onClick={handleFormSubmission}
            >
              Yes!
            </button>
          </CModalFooter>
        </CModal>
        <div className="Back_btn">
          <h2>Add Subjects</h2>
          <button className="btn btn-primary" onClick={() => navigate(-1)}>
            Back
          </button>
        </div>

        <CContainer fluid>
          {loading ? (
            <div className="spinner-container">
              <RotatingLines
                visible={true}
                height="96"
                width="96"
                strokeColor="#506EE4"
                strokeWidth="5"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </div>
          ) : (
            <CForm
              className="row mt-5 needs-validation"
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
            >
              <CRow className="justify-content-center pl-5 pr-5">
                <CCol md={8} className="">
                  <div className=" mt-3 ">
                    <div className="">
                      <div className="d-flex flex-column justify-content-center align-items-center">
                        {divs.map((div, index) => (
                          <div
                            className="w-80 d-flex flex-column gap-3 mb-3 border border-2 rounded p-4"
                            key={index}
                          >
                            {index !== 0 && index === divs.length - 1 && (
                              <div className="d-flex justify-content-end">
                                <RemoveCircleIcon
                                  onClick={deleteDiv}
                                  color="error"
                                  style={{ cursor: "pointer", float: "end" }}
                                />
                              </div>
                            )}{" "}
                            <div>
                              <CFormSelect
                                label="Level"
                                name="category"
                                required
                                value={div.stage}
                                onChange={(e) => {
                                  handleChange(index, "stage", e.target.value);
                                  getGrades(e.target.value);
                                }}
                                className="form-select"
                              >
                                <option selected disabled value="">
                                  Choose...
                                </option>
                                {allStages &&
                                  allStages.map((cat) => (
                                    <option key={cat._id} value={cat._id}>
                                      {cat.stage}
                                    </option>
                                  ))}
                              </CFormSelect>
                            </div>
                            {/* Grades */}
                            <div>
                              <label className="form-label">Grades</label>

                              <div
                                key={index}
                                className="d-flex align-items-center mb-2 gap-2"
                              >
                                <CFormSelect
                                  required
                                  value={div.grade}
                                  onChange={(e) =>
                                    handleChange(
                                      index,
                                      "grade",
                                      e.target.value,
                                      "",
                                      "grade"
                                    )
                                  }
                                  className="form-select flex-grow-1"
                                >
                                  <option selected disabled value="">
                                    Choose...
                                  </option>
                                  {allGrades &&
                                    allGrades.map((cat) => (
                                      <option key={cat._id} value={cat._id}>
                                        {cat.grade}
                                      </option>
                                    ))}
                                </CFormSelect>
                              </div>
                            </div>
                            {/* Subjects */}
                            <div>
                              <label className="form-label">Subjects</label>
                              {div.subjects &&
                                div.subjects.map((subject, subjectIndex) => (
                                  <div
                                    key={subjectIndex}
                                    className="d-flex align-items-center mb-2 gap-2"
                                  >
                                    <CFormSelect
                                      value={subject.id}
                                      onChange={(e) =>
                                        handleChange(
                                          index,
                                          "subjects",
                                          e.target.value,
                                          subjectIndex,
                                          "subjects"
                                        )
                                      }
                                      className="form-select flex-grow-1"
                                    >
                                      <option selected disabled value="">
                                        Choose...
                                      </option>
                                      {getCats.map((cat) => (
                                        <option key={cat._id} value={cat._id}>
                                          {cat.subject}
                                        </option>
                                      ))}
                                    </CFormSelect>

                                    <h6>OR</h6>

                                    <input
                                      style={{ width: "200px" }}
                                      className="form-control"
                                      type="text"
                                      placeholder="Enter Subject Name"
                                      value={subject.name}
                                      onChange={(e) =>
                                        handleChange(
                                          index,
                                          "subjects",
                                          e.target.value,
                                          subjectIndex,
                                          "subjectstext"
                                        )
                                      }
                                    />

                                    {subjectIndex ===
                                      div.subjects.length - 1 && (
                                      <AddCircleOutlinedIcon
                                        color="primary"
                                        style={{
                                          cursor: "pointer",
                                          marginLeft: "10px",
                                        }}
                                        onClick={() => {
                                          const isValid =
                                            (!!subject?.name &&
                                              !!!subject?.id) ||
                                            (!!!subject?.name && !!subject?.id);
                                          console.log(
                                            "student Name",
                                            subject?.name,
                                            "student id ",
                                            subject.id
                                          );
                                          console.log("0-0-0-0-", isValid);
                                          if (!isValid) {
                                            notification.warning({
                                              message: "warning",
                                              description:
                                                "Only one Subject field must be filled, and the other must remain empty.",
                                            });
                                          } else {
                                            addSubject(index);
                                          }
                                        }}
                                      />
                                    )}
                                  </div>
                                ))}
                            </div>
                          </div>
                        ))}

                        <button
                          className="btn btn-primary mt-3 m-auto"
                          onClick={addNewDiv}
                        >
                          Add More
                        </button>
                      </div>
                    </div>
                  </div>
                </CCol>
              </CRow>
              <div className="buttons text-center pt-3">
                <CButton color="primary" type="submit">
                  Submit form
                </CButton>

                <CButton color="dark" type="reset" onClick={resetData}>
                  Reset form
                </CButton>
              </div>
            </CForm>
          )}
        </CContainer>
      </CContainer>
    </>
  );
}

export default AddSubject;
