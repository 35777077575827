import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Button, Checkbox, Table, Space } from "antd";
import {
  CContainer,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import { notification } from "antd";
import { RotatingLines } from "react-loader-spinner";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import * as XLSX from "xlsx"; // Add XLSX for exporting to Excel
import { AuthContext } from "../../../helpers/AuthContext";
import { saveAs } from "file-saver";

function Sections() {
  const token = localStorage.getItem("accessToken");
  const [couponsList, setCouponsList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [loading, setLoading] = useState(false);

  const [collecIds, setCollecIds] = useState([]);
  const [filterKeyword, setFilterKeyword] = useState("");
  const [teacherType, setTeacherType] = useState("");

  const [userType, setUserType] = useState("");

  const [filterStage, setFilterStage] = useState("");
  const [filterGrade, setFilterGrade] = useState("");
  const [filterSection, setFilterSection] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);

  const [dateRange, setdateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const academicYearId = localStorage.getItem("academicYearId");
  const upcomingyear = localStorage.getItem("upcomingyear");
  const { authState } = useContext(AuthContext);
  const [totalDoc, setTotalDoc] = useState();
  const navigate = useNavigate();

  const getCouponList = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${
          process.env.REACT_APP_DEV_BASE_URL
        }/api/v1/academics/grade-wise-subjects?stage=${filterStage}&grade=${filterGrade}&search=${filterKeyword}&academicYearId=${
          academicYearId == authState?.startYear
            ? academicYearId
            : authState?.startYear
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setCouponsList(res?.data?.data);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  console.log("--0-0-0-", couponsList);
  useEffect(() => {
    getCouponList();
  }, [
    filterGrade,
    filterStage,
    filterKeyword,
    academicYearId,
    authState?.startYear,
    upcomingyear,
  ]);
  // const [selectAll, setSelectAll] = useState(false);
  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    const updatedStudents = couponsList.map((student) => ({
      ...student,
      selected: !selectAll,
    }));
    setCouponsList(updatedStudents);
    setCollecIds(
      !selectAll ? updatedStudents.map((student) => student._id) : []
    );
  };

  const handleSelect = (id) => {
    const updatedStudents = couponsList.map((student) => {
      if (student._id === id) {
        student.selected = !student.selected;
        if (student.selected) {
          setCollecIds((prev) => [...prev, id]);
        } else {
          setCollecIds((prev) => prev.filter((studentId) => studentId !== id));
        }
      }
      return student;
    });
    setCouponsList(updatedStudents);
  };
  const columns = [
    {
      title: <Checkbox checked={selectAll} onChange={toggleSelectAll} />,
      dataIndex: "selected",
      render: (_, record) => (
        <Checkbox
          checked={record?.selected}
          onChange={() => handleSelect(record?._id)}
        />
      ),
    },
    {
      title: "S.No.",
      dataIndex: "index",
      key: "index",
      width: 0,
      render: (_, __, index) => index + 1,
    },
    {
      title: "Stage",
      render: (_, record) => record?.stage?.stage,
      sorter: (a, b) => a?.firstName?.localeCompare(b?.firstName),
    },
    {
      title: "Grade",
      render: (_, record) => record?.grade?.grade,
    },

    {
      title: "Subject",
      key: "subjects",
      render: (_, record) => {
        return record.subjects.map((sub) => sub.subject).join(", ");
      },
    },

    {
      title: "Action",
      render: (_, record) => (
        <Space>
          {authState.startYear !== academicYearId &&
          authState.startYear !== upcomingyear ? (
            <>
              <button
                className="btn btn-primary"
                disabled
                onClick={() => {
                  navigate("/school/edit-subjects", {
                    state: {
                      catid: record._id,
                      academicYear: authState?.startYear,
                    },
                  });
                }}
              >
                Edit
              </button>
            </>
          ) : (
            <button
              className="btn btn-primary"
              onClick={() => {
                navigate("/school/edit-subjects", {
                  state: {
                    catid: record._id,
                    academicYear: authState?.startYear,
                  },
                });
              }}
            >
              Edit
            </button>
          )}
        </Space>
      ),
    },
  ];

  const getSections = async (id) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/stage-grade-section/stage/${filterStage}/grade/${id}/section`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAllSections(res?.data?.records);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStages();
  }, []);

  const [requestData, setRequestData] = useState({
    is_issued: true,
    remark: "",
    userId: "",
  });

  const handleApprove = async () => {
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/behaviorpoint/coupon-approval/${requestData?.userId}`,
        {
          is_issued: true,
          remark: requestData?.remark,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      if (res?.data?.status == "success") {
        notification.success({
          message: "Success",
          description: res?.data?.message,
        });
        getCouponList();
        setVisible(false);
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: "error",
        description: error?.message,
      });
    }
  };
  const [allStages, setAllStages] = useState([]);
  const [allGrades, setAllGrades] = useState([]);
  const [allSections, setAllSections] = useState([]);

  const getStages = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/stage-grade-section/stage`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAllStages(res?.data?.records);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStages();
  }, []);
  const getGrades = async (stageId, initial = false) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/stage-grade-section/stage/${stageId}/grade`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setAllGrades(response?.data?.records);
    } catch (error) {
      console.error("Error fetching grades:", error);
    }
    return [];
  };
  const header = [
    "Name",
    "Grade",
    "Section",
    "ITS Number",
    "Date",
    "Coupon Request",
    "Requested Coupon",
    "Remark",
  ];
  const exportToExcel = () => {
    // Format data with the header
    const dataWithHeader = couponsList?.map((res) => ({
      Name: `${res.requested_by.firstName} ${res.requested_by.lastName}`,
      Grade: res?.requested_by?.stageGradeSection?.grade?.grade,
      Type: res?.requested_by?.teacherType?.type,
      Section: res?.requested_by?.stageGradeSection?.section?.section,
      "ITS Number": res?.requested_by?.itsNo,
      Date: moment(res.requested_date).format("DD-MM-YYYY"),
      "Coupon Request": res.is_issued ? "Approved" : "Pending",
      "Requested Coupon": res?.requested_coupon,
      Remark: res.remark,
    }));

    // Create a new worksheet
    const worksheet = XLSX.utils.json_to_sheet(dataWithHeader, { header });

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Write the workbook and trigger the download
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Use file-saver to save the file
    const dataBlob = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(dataBlob, "couponRequestExcel.xlsx");
  };

  const deleteSelected = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/academics/grade-wise-subjects`,
        {
          data: { ids: collecIds },
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setVisible(false);
      getCouponList();
      notification.success({ message: "Deleted Successfully" });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <CContainer fluid>
        <CModal
          backdrop="static"
          visible={visible}
          onClose={() => setVisible(false)}
          aria-labelledby="RemarkModalLabel"
        >
          <CModalHeader>
            <CModalTitle id="RemarkModalLabel">Approval Remark</CModalTitle>
          </CModalHeader>
          <CModalBody className="pb-3">
            <textarea
              className="form-control"
              rows="4"
              placeholder="Enter your remarks here..."
              value={requestData?.remark}
              onChange={(e) =>
                setRequestData({ ...requestData, remark: e.target.value })
              }
            ></textarea>
          </CModalBody>
          <CModalFooter className="pl-0">
            <button
              className="btn btn-outline-secondary ms-0 me-2"
              onClick={() => setVisible(false)}
            >
              Cancel
            </button>
            <button className="btn btn-primary" onClick={() => handleApprove()}>
              Approve
            </button>
          </CModalFooter>
        </CModal>

        <div className="mb-2">
          <div className="d-flex justify-content-between newmwnu mb-2">
            <h2 className="mb-2">All Subjects</h2>
            <div className="d-flex gap-2  Assign_section mb-1">
              {authState.startYear !== academicYearId &&
              authState.startYear !== upcomingyear ? (
                <>
                  <button className="btn btn-dark" disabled>
                    Add Subjects
                  </button>
                </>
              ) : (
                <button
                  className="btn btn-dark"
                  onClick={() =>
                    navigate("/school/add-subjects", {
                      state: { academicYear: authState?.startYear },
                    })
                  }
                >
                  Add Subjects
                </button>
              )}
            </div>
          </div>

          <div className="d-flex justify-content-between">
            <div className="filters d-flex gap-3 pb-2 align-items-center flex-wrap Teacher_Leave_mobile space_div">
              <select
                value={filterStage}
                onChange={(e) => {
                  setFilterStage(e.target.value);
                  getGrades(e.target.value);
                }}
                style={{ width: "200px" }}
                className="form-control form-select"
              >
                <option value="">Select Stage</option>
                {allStages.map((stage) => (
                  <option key={stage._id} value={stage._id}>
                    {stage.stage}
                  </option>
                ))}
              </select>

              <select
                placeholder="All Divisions"
                value={filterGrade}
                onChange={(e) => setFilterGrade(e.target.value)}
                style={{ width: "200px" }}
                className="form-control form-select"
              >
                <option value="">Select Grade</option>
                {allGrades.map((stage) => (
                  <option key={stage._id} value={stage._id}>
                    {stage.grade}
                  </option>
                ))}
              </select>

              <div className="d-flex flex-column">
                <input
                  style={{ width: "200px" }}
                  className="form-control"
                  type="text"
                  placeholder="Enter keyword"
                  value={filterKeyword}
                  onChange={(e) => setFilterKeyword(e.target.value)}
                />
              </div>

              <button
                onClick={() => {
                  setFilterKeyword("");
                  setFilterStage("");
                  setFilterGrade("");
                }}
                className="btn btn-primary"
              >
                Reset Filter
              </button>
            </div>
            {authState.startYear !== academicYearId &&
            authState.startYear !== upcomingyear ? (
              <>
                <button
                  onClick={deleteSelected}
                  disabled
                  className="btn btn-danger"
                >
                  Delete
                </button>
              </>
            ) : (
              <button onClick={deleteSelected} className="btn btn-danger">
                Delete
              </button>
            )}
          </div>
        </div>

        {loading && (
          <div className="spinner-container">
            <RotatingLines
              visible={true}
              height="96"
              width="96"
              strokeColor="#506EE4"
              strokeWidth="5"
              animationDuration="0.75"
              ariaLabel="rotating-lines-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        )}

        <Table
          rowKey="_id"
          columns={columns}
          className="bordered-table"
          dataSource={couponsList}
          pagination={{
            defaultPageSize: 25,
            pageSizeOptions: ["25", "50", "100"],
            showSizeChanger: true,
            locale: {
              items_per_page: "",
            },
          }}
        />
      </CContainer>
    </div>
  );
}

export default Sections;
