import React, { useState } from "react";
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CRow,
  CContainer,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
} from "@coreui/react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Button, Typography, Modal, notification } from "antd";
function AddSchoolType() {
  const token = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: "",
    description: "",
  });

  const [validated, setValidated] = useState(false);
  const [visible, setVisible] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      setVisible(true);
    }
  };

  const handleFormSubmission = async () => {
    setVisible(false);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/academics/school-type`,
        {
          type :schoolType
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      notification.success({
        message: "Success",
        description: "Add School Type  successfully!",
      });
      navigate("/school/school-type");
      resetData();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const resetData = () => {
    setValidated(false);
   setschoolType("")
  };
const [schoolType , setschoolType] = useState('')
  return (
    <>
      <CContainer fluid className="container overflow-auto">
        <CModal
          backdrop="static"
          visible={visible}
          onClose={() => setVisible(false)}
          aria-labelledby="StaticBackdropExampleLabel"
        >
          <CModalHeader>
            <CModalTitle id="StaticBackdropExampleLabel">
              Form Submission
            </CModalTitle>
          </CModalHeader>
          <CModalBody className="pb-3">
            Are you sure you wish to submit this form?
          </CModalBody>
          <CModalFooter className="pl-0">
            {/* <button
              className="btn btn-dark ms-0 me-3"
              onClick={() => setVisible(false)}
            >
              Cancel
            </button>
            <CButton color="primary" onClick={handleFormSubmission}>
              Yes!
            </CButton> */}

            <button
              className="btn btn-dark ms-0 me-3"
              onClick={() => setVisible(false)}
            >
              Cancel
            </button>
            <CButton color="primary" onClick={handleFormSubmission}>
              Yes!
            </CButton>
          </CModalFooter>
        </CModal>

        <div className="Back_btn">
          <h2> School Type</h2>
          <button className="btn btn-primary" onClick={() => navigate(-1)}>
            Back
          </button>
        </div>

        {/* <div className="display-5 text-center pb-3 font-weight-bold">
          {" "}
          Allergies Information
        </div> */}

        <CContainer fluid>
          <CForm
            className="needs-validation mt-5"
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
            <CRow className="justify-content-center">
              <CCol md={6} className="d-flex flex-column align-items-center">
                <div className="w-100">
                
                </div>
                <div className="w-100 pt-4">
                  <h4>School Type:</h4>
                  <div className=" pt-3">
                  

<input
                        className="form-control"
                        placeholder="Enter School Type here"
                        
                        type="text"
                        value={schoolType}
                        onChange={(e)=>setschoolType(e.target.value)}
                        />
                                      
                    {/* <label htmlFor="floatingTextarea">
                      Please enter School Type here
                    </label> */}
                  </div>
                </div>
              </CCol>
            </CRow>

            <div className="buttons text-center pt-3">
              <CButton color="primary" type="submit">
                Submit form
              </CButton>
              <CButton color="dark" type="reset" onClick={resetData}>
                Reset form
              </CButton>
            </div>
          </CForm>
        </CContainer>
      </CContainer>
    </>
  );
}

export default AddSchoolType;
