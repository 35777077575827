import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import {
  Table,
  Button,
  Modal,
  Input,
  Radio,
  Space,
  Pagination,
  notification,
} from "antd";
import {
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CPagination,
  CPaginationItem,
} from "@coreui/react";
import axios from "axios";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import { RotatingLines } from "react-loader-spinner";

function SchoolTypeListing() {
  const { Column } = Table;
  const token = localStorage.getItem("accessToken");
  const navigate = useNavigate();

  const [dummyModules, setDummyModules] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [collecIds, setCollecIds] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const [filterKeyword, setFilterKeyword] = useState("");
  const [pageSize, setPageSize] = useState(4);
  const [currentPage, setCurrentPage] = useState(1);

  const getModules = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/academics/school-type`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
        console.log("response" , response)
      setDummyModules(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching houses:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getModules();
  }, []);

  const toggleSelectAll = () => {
    const updatedSelectAll = !selectAll;
    setSelectAll(updatedSelectAll);
    const updatedCollecIds = updatedSelectAll
      ? dummyModules.map((module) => module._id)
      : [];
    setCollecIds(updatedCollecIds);
    setDummyModules((prevModules) =>
      prevModules.map((module) => ({ ...module, selected: updatedSelectAll }))
    );
  };

  const handleSelect = (index) => {
    const updatedModules = [...dummyModules];
    const moduleId = updatedModules[index]._id;

    updatedModules[index].selected = !updatedModules[index].selected;

    if (updatedModules[index].selected) {
      setCollecIds((prevIds) => [...prevIds, moduleId]);
    } else {
      setCollecIds((prevIds) => prevIds.filter((id) => id !== moduleId));
    }

    setDummyModules(updatedModules);
  };


  const deleteSelected = async () => {
    
    try {
      await axios.delete(
        `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/academics/school-type`,
        {
          headers: { Authorization: `Bearer ${token}` },
          data: { ids: collecIds }, 
        }
      );
  
      setVisible(false);
      setCollecIds([]);
      getModules();
    } catch (error) {
      console.error("Error deleting selected:", error);
    }
  };
  

  

  return (
    <div>
      <CModal
        backdrop="static"
        visible={visible}
        onClose={() => {
          setVisible(false);
          setCollecIds([]);
        }}
      >
        <CModalHeader>
          <CModalTitle>Delete allergy</CModalTitle>
        </CModalHeader>
        <CModalBody className="pb-3">
          Are you sure you wish to delete this allergy?
        </CModalBody>
        <CModalFooter className="pl-0">
          {/* <CButton
            color="secondary"
            className="mr-2"
            onClick={() => setVisible(false)}
          >
            Close
          </CButton>
          <CButton color="primary" onClick={deleteSelected}>
            Yes!
          </CButton> */}
          <button
            className="btn btn-dark ms-0 me-2"
            onClick={() => setVisible(false)}
          >
            Cancel
          </button>
          <button className="btn btn-primary" onClick={deleteSelected}>
            Yes, Delete
          </button>
        </CModalFooter>
      </CModal>

      <div className="mb-2">
        <div className="row pb-3">
          <div className="col-md-6">
            <h2>School Type Listing</h2>
          </div>
          <div className="col-md-6">
            <button
              className="btn btn-primary float-end"
              onClick={() => navigate("/school/add-school-type")}
            >
              Add School Type
            </button>
          </div>
        </div>
      </div>

      <div className="mb-3 d-flex align-items-center gap-3 flex-end-btn-input all_list_div">
       
      </div>
      <div className="pb-3 mobile_flex">
        {/* <button className="btn btn-primary" onClick={() => {
           markActiveInactive(true)
        }}>Mark Active</button>

        <button className="btn btn-dark" onClick={() => {
           markActiveInactive(false)
        }}>Mark Inactive</button> */}

        <button style={{ marginLeft: "8px" }} onClick={deleteSelected} className="btn btn-danger">
          Delete Selected
        </button>
      </div>

      {loading && (
        <div className="spinner-container">
          <RotatingLines
            visible={true}
            height="96"
            width="96"
            strokeColor="#506EE4"
            strokeWidth="5"
            animationDuration="0.75"
            ariaLabel="rotating-lines-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      )}

      <div className="table-responsive">
        <Table
          dataSource={dummyModules}
          rowKey="_id"
          pagination={{
            defaultPageSize: 25, // Set the default page size
            pageSizeOptions: ["25", "50", "100", "All"], // Options for page size selection
            showSizeChanger: true, 
            position: ["bottomRight"],
            locale: {
              items_per_page: "",
            },
          }}
          className="bordered-table"
        >
          <Column
            title={
              <input
                type="checkbox"
                checked={selectAll}
                onChange={toggleSelectAll}
              />
            }
            render={(_, record , index) => (
              <input
                type="checkbox"
                checked={record.selected}
                onChange={() => handleSelect(index)}
              />
            )}
          />
          <Column
            title="School Type"
            dataIndex="type"
          
          />
          <Column
            title="Status"
                      dataIndex="Status"
                      render =  {(_, record) =>
                        record?.active ? "Active" : "Inactive"}
          
          />
         
          <Column
            title="Action"
            render={(_, record) => (
              <Space>
                <Button
                  onClick={() =>
                    navigate("/school/edit-school-type", {
                      state: { catid: record._id },
                    })
                  }
                >
                  Edit
                </Button>
              
              </Space>
            )}
          />
        </Table>
      </div>

    
     
    </div>
  );
}

export default SchoolTypeListing;
