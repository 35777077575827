import React, { Suspense, useEffect, useState } from "react";
import {
  HashRouter,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
// import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
// import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
// import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Import Bootstrap JS (including Popper.js)

import FullLayout from "./layouts/FullLayout";
import Loader from "./layouts/loader/Loader";
import { AuthContext } from "./helpers/AuthContext.js";

import ForgotPassword from "./views/ForgotPassword.js";
import ResetPassword from "./views/ResetPassword.js";
import SchoolForgotPassword from "./views/School-admin/ForgotPassword/ForgotPassword.jsx";
import SchoolResetPassword from "./views/School-admin/ResetPassword/ResetPassword.jsx";
import "./style/css/style.css";
import { Provider } from "react-redux";
import store from "./redux/store.js";
import AdminLoginTemplate from "./views/AdminLoginTemplate.js";
import SchoolAdminLoginTemp from "./views/School-admin/SchoolAdminLoginTemp.js";
// import "./index.scss"
import "./style/scss/pages/_settings.scss";
import TeacherLogin from "./views/TeacherAdmin/Login/login";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
// Pages

const LoginTemplate = React.lazy(() => import("./views/Login_Template.js"));

const Register = React.lazy(() => import("./views/Register"));

const App = () => {
  const navigate = useNavigate();

  const [authState, setAuthState] = useState({
    role: "",
    name: "",
    class: "",
    id: "",
    fatherName: "",
    startYear: "",
    academiclenth: "",
  });

  const accessToken = localStorage.getItem("accessToken");
  const usertype = localStorage.getItem("user");

  // useEffect(() => {
  //   // Only run navigation logic if the user type exists.
  //   if (usertype) {
  //     if (usertype === "school-admin") {
  //       navigate("/school/dashboard");
  //     } else if (usertype === "teacher") {
  //       navigate("/teacher/dashboard");
  //     } else if (usertype === "student") {
  //       navigate("/studentview");
  //     }
  //   }
  // }, [usertype, navigate]);
  // console.log("accessToken", accessToken);
  // useEffect(() => {
  //   if (accessToken) {
  //     switch (usertype) {
  //       case "school-admin":
  //         navigate("/school/dashboard");
  //         break;
  //       case "teacher":
  //         navigate("/teacher/dashboard");
  //         break;
  //       case "student":
  //         navigate("/studentview");
  //         break;
  //       default:
  //         navigate("/");
  //     }
  //   }
  // }, [accessToken, usertype, navigate]);
  return (
    <Provider store={store}>
      {/* <HashRouter> */}
      <Suspense fallback={<Loader />}>
        <AuthContext.Provider value={{ authState, setAuthState }}>
          <Routes>
            <Route element={<PublicRoute />}>
              <Route
                path="/student/login/:schoolName/:uniqueId"
                name=""
                element={<LoginTemplate />}
              />

              <Route
                path="/forgotpassword"
                name="Forgot Password"
                element={<ForgotPassword />}
              />
              <Route
                path="/resetpassword/:token"
                name="Reset Password"
                element={<ResetPassword />}
              />

              <Route
                path="/admin"
                name="Admin Page"
                element={<AdminLoginTemplate />}
              />
              <Route
                path="/school/admin/:schoolName/:uniqueId"
                name="SchoolAdmin Page"
                element={<SchoolAdminLoginTemp />}
              />
              <Route
                path="/school/forgotpassword"
                name="SchoolAdmin Page"
                element={<SchoolForgotPassword />}
              />
              <Route
                path="/school/resetpassword/:token"
                name="SchoolAdmin Page"
                element={<SchoolResetPassword />}
              />

              <Route
                path="/teacher/login/:schoolName/:uniqueId"
                name="Teacher Login"
                element={<TeacherLogin />}
              />

              <Route
                exact
                path="/register"
                name="Register Page"
                element={<Register />}
              />
            </Route>

            {/* Protected routes based on role */}
            {/* <Route element={<ProtectedRoute allowedRoles={["school-admin"]} />}>
              <Route path="/school/dashboard" element={<FullLayout />} />
            </Route>

            <Route element={<ProtectedRoute allowedRoles={["teacher"]} />}>
              <Route path="/teacher/dashboard" element={<FullLayout />} />
              {console.log("teacher")}
            </Route>

            <Route element={<ProtectedRoute allowedRoles={["student"]} />}>
              <Route path="*" element={<FullLayout />} />
              {console.log("ssssss")}
            </Route> */}
            <Route element={<ProtectedRoute />}>
              <Route path="*" name="Home" element={<FullLayout />} />
            </Route>
          </Routes>
        </AuthContext.Provider>
      </Suspense>
      {/* </HashRouter> */}
    </Provider>
  );
};

export default App;
