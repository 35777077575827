import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Button, Checkbox, Table, Space } from "antd";
import {
  CContainer,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import { notification } from "antd";
import { RotatingLines } from "react-loader-spinner";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import * as XLSX from "xlsx"; // Add XLSX for exporting to Excel
import { AuthContext } from "../../../helpers/AuthContext";
import { saveAs } from "file-saver";

function WorkingdaysList() {
  const token = localStorage.getItem("accessToken");
  const academicYearId = localStorage.getItem("academicYearId");
  const upcomingyear = localStorage.getItem("upcomingyear");
  const { authState } = useContext(AuthContext);
  const [couponsList, setCouponsList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [loading, setLoading] = useState(false);

  const [collecIds, setCollecIds] = useState([]);
  const [filterKeyword, setFilterKeyword] = useState("");
  const [teacherType, setTeacherType] = useState("");

  const [userType, setUserType] = useState("");

  const [filterStage, setFilterStage] = useState("");
  const [filterGrade, setFilterGrade] = useState("");
  const [filterSection, setFilterSection] = useState("");
  const [filterStatus, setFilterStatus] = useState("");

  const [dateRange, setdateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const [totalDoc, setTotalDoc] = useState();
  const navigate = useNavigate();

  const getCouponList = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${
          process.env.REACT_APP_DEV_BASE_URL
        }/api/v1/academics/working-day?startDate=${
          startDate ? moment(startDate).format("YYYY-MM-DD") : ""
        }&endDate=${
          endDate ? moment(endDate).format("YYYY-MM-DD") : ""
        }&search=${filterKeyword}&academicYearId=${
          academicYearId == authState?.startYear
            ? academicYearId
            : authState?.startYear
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("repsosne is here", res);
      setCouponsList(res?.data?.data);
      console.log("checking inside working day", couponsList);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (startDate) {
      if (endDate) {
        getCouponList();
      }
    } else {
      getCouponList();
    }
  }, [
    dateRange,
    filterKeyword,
    academicYearId,
    authState?.startYear,
    upcomingyear,
  ]);
  //   const toggleSelectAll = () => {
  //     setSelectAll(!selectAll);
  //     const updatedStudents = selectedUsers.map((student) => ({
  //       ...student,
  //       selected: !selectAll,
  //     }));
  //     setSelectedUsers(updatedStudents);
  //     setCollecIds(
  //       !selectAll ? updatedStudents.map((student) => student._id) : []
  //     );
  //   };

  //   const handleSelect = (id) => {
  //     const updatedStudents = selectedUsers.map((student) => {
  //       if (student._id === id) {
  //         student.selected = !student.selected;
  //         if (student.selected) {
  //           setCollecIds((prev) => [...prev, id]);
  //         } else {
  //           setCollecIds((prev) => prev.filter((studentId) => studentId !== id));
  //         }
  //       }
  //       return student;
  //     });
  //     setSelectedUsers(updatedStudents);
  //   };
  const columns = [
    //  {
    //   title: <Checkbox checked={selectAll} onChange={toggleSelectAll} />,
    //   dataIndex: "selected",
    //   render: (_, record) => (
    //     <Checkbox
    //       checked={record.selected}
    //       onChange={() => handleSelect(record._id)}
    //     />
    //   ),
    // },

    {
      title: "S.No.",
      dataIndex: "index",
      key: "index",
      width: 0,
      render: (_, __, index) => index + 1, // Calculates serial number
    },
    {
      title: "Start Date",
      render: (_, record) => (
        <span>{moment(record?.start_date).format("DD/MM/YYYY")} </span>
      ),
      // sorter: (a, b) => a?.firstName?.localeCompare(b?.firstName),
    },
    {
      title: "End Date",
      render: (_, record) => (
        <span> {moment(record?.end_date).format("DD/MM/YYYY")}</span>
      ),
    },

    {
      title: "School Type",
      render: (_, record) => record?.school_type_id?.type,
    },
    // {
    //   title: "Status",
    //   render: (_, record) => (record?.active ? "Active" : "Inactive"),
    // },
    {
      title: "Action",
      render: (_, record) => (
        <Space>
          {authState.startYear !== academicYearId &&
          authState.startYear !== upcomingyear ? (
            <>
              <Button
                type="primary"
                onClick={() =>
                  navigate("/school/EditDay", {
                    state: { catid: record._id },
                  })
                }
                disabled
              >
                Edit
              </Button>
            </>
          ) : (
            <Button
              type="primary"
              onClick={() =>
                navigate("/school/EditDay", {
                  state: {
                    catid: record._id,
                    academicYear: authState?.startYear,
                  },
                })
              }
            >
              Edit
            </Button>
          )}

          {authState.startYear !== academicYearId &&
          authState.startYear !== upcomingyear ? (
            <>
              <Button
                danger
                onClick={() => {
                  setCollecIds([record._id]);
                  setVisible(true);
                }}
                disabled
              >
                Delete
              </Button>
            </>
          ) : (
            <Button
              danger
              onClick={() => {
                setCollecIds([record._id]);
                setVisible(true);
              }}
            >
              Delete
            </Button>
          )}
        </Space>
      ),
    },
  ];

  const [allStages, setAllStages] = useState([]);
  const [allGrades, setAllGrades] = useState([]);
  const [allSections, setAllSections] = useState([]);

  const getStages = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/stage-grade-section/stage`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAllStages(res?.data?.records);
    } catch (error) {
      console.log(error);
    }
  };

  const getGrades = async (id) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/stage-grade-section/stage/${id}/grade`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAllGrades(res?.data?.records);
    } catch (error) {
      console.log(error);
    }
  };
  console.log("total doc is this ", totalDoc);
  // const getSections = async (id) => {
  //   try {
  //     const res = await axios.get(
  //       `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/academics/school-type`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     setAllSections(res?.data?.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   getSections();
  // }, []);

  const [requestData, setRequestData] = useState({
    is_issued: true,
    remark: "",
    userId: "",
  });

  // const handleApprove = async () => {
  //   try {
  //     const res = await axios.put(
  //       `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/behaviorpoint/coupon-approval/${requestData?.userId}`,
  //       {
  //         is_issued: true,
  //         remark: requestData?.remark,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //         },
  //       }
  //     );
  //     if (res?.data?.status == "success") {
  //       notification.success({
  //         message: "Success",
  //         description: res?.data?.message,
  //       });
  //       getCouponList();
  //       setVisible(false);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     notification.error({
  //       message: "error",
  //       description: error?.message,
  //     });
  //   }
  // };

  const header = [
    "Name",
    "Grade",
    "Section",
    "ITS Number",
    "Date",
    "Coupon Request",
    "Requested Coupon",
    "Remark",
  ];
  // const exportToExcel = () => {
  //   // Format data with the header
  //   const dataWithHeader = couponsList?.map((res) => ({
  //     Name: `${res.requested_by.firstName} ${res.requested_by.lastName}`,
  //     Grade: res?.requested_by?.stageGradeSection?.grade?.grade,
  //     Type: res?.requested_by?.teacherType?.type,
  //     Section: res?.requested_by?.stageGradeSection?.section?.section,
  //     "ITS Number": res?.requested_by?.itsNo,
  //     Date: moment(res.requested_date).format("DD-MM-YYYY"),
  //     "Coupon Request": res.is_issued ? "Approved" : "Pending",
  //     "Requested Coupon": res?.requested_coupon,
  //     Remark: res.remark,
  //   }));

  //   // Create a new worksheet
  //   const worksheet = XLSX.utils.json_to_sheet(dataWithHeader, { header });

  //   // Create a new workbook and append the worksheet
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  //   // Write the workbook and trigger the download
  //   const excelBuffer = XLSX.write(workbook, {
  //     bookType: "xlsx",
  //     type: "array",
  //   });

  //   // Use file-saver to save the file
  //   const dataBlob = new Blob([excelBuffer], {
  //     type: "application/octet-stream",
  //   });
  //   saveAs(dataBlob, "couponRequestExcel.xlsx");
  // };
  const deleteSelected = async () => {
    const dataToSend = {
      ids: collecIds, // Send the IDs you want to delete
    };

    try {
      // Loop through each id in collecIds and delete it
      for (const id of collecIds) {
        await axios.delete(
          `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/academics/working-day`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            data: dataToSend, // Attach the data in the 'data' property
          }
        );
      }
      notification.success({
        message: "Success",
        description: "Working Days Delete Successfully",
      });
      setVisible(false); // Hide the modal or close the delete interface
      setCollecIds([]); // Clear the list of IDs to delete
      getCouponList(); // Fetch the updated list of modules or years
    } catch (error) {
      console.error("Error deleting selected:", error);
    }
  };
  return (
    <div>
      <CContainer fluid>
        <CModal
          backdrop="static"
          visible={visible}
          onClose={() => {
            setVisible(false);
            setCollecIds([]);
          }}
        >
          <CModalHeader>
            <CModalTitle>Delete Working Days </CModalTitle>
          </CModalHeader>
          <CModalBody className="pb-3">
            Are you sure you wish to delete this Working Days ?
          </CModalBody>
          <CModalFooter className="pl-0">
            <button
              className="btn btn-dark ms-0 me-2"
              onClick={() => setVisible(false)}
            >
              Cancel
            </button>
            <button className="btn btn-primary" onClick={deleteSelected}>
              Yes, Delete
            </button>
          </CModalFooter>
        </CModal>

        <div className="mb-2">
          <div className="d-flex justify-content-between newmwnu mb-2">
            <h2 className="mb-2">Working Days And Timing</h2>
            <div className="d-flex gap-2  Assign_section mb-1">
              {authState.startYear !== academicYearId &&
              authState.startYear !== upcomingyear ? (
                <>
                  <button
                    className="btn btn-dark"
                    disabled
                    onClick={() => navigate("/school/add-working-days")}
                  >
                    Add New
                  </button>
                </>
              ) : (
                <button
                  className="btn btn-dark"
                  onClick={() =>
                    navigate("/school/add-working-days", {
                      state: {
                        academicYear: authState?.startYear,
                      },
                    })
                  }
                >
                  Add New
                </button>
              )}
            </div>
          </div>

          <div className="d-flex justify-content-between">
            <div className="filters d-flex gap-3 pb-2 align-items-center flex-wrap Teacher_Leave_mobile space_div">
              <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  setdateRange(update);
                }}
                isClearable={false}
                placeholderText="Select Date Range"
                dateFormat="dd-MM-yyyy"
              />

              <div className="d-flex flex-column">
                <input
                  style={{ width: "200px" }}
                  className="form-control"
                  type="text"
                  placeholder="Enter keyword"
                  value={filterKeyword}
                  onChange={(e) => setFilterKeyword(e.target.value)}
                />
              </div>

              <button
                onClick={() => {
                  setFilterSection("");
                  setFilterGrade("");
                  setFilterStage("");
                  setAllStages([]);
                  setAllGrades([]);
                  setAllSections([]);
                  setUserType("");
                  setdateRange([null, null]);
                  setFilterKeyword("");
                }}
                className="btn btn-primary"
              >
                Reset Filter
              </button>
            </div>

            {/* <button className="btn btn-danger">Delete</button> */}
          </div>
        </div>

        {loading && (
          <div className="spinner-container">
            <RotatingLines
              visible={true}
              height="96"
              width="96"
              strokeColor="#506EE4"
              strokeWidth="5"
              animationDuration="0.75"
              ariaLabel="rotating-lines-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        )}

        <Table
          rowKey="_id"
          columns={columns}
          className="bordered-table"
          dataSource={couponsList}
          pagination={{
            defaultPageSize: 25,
            pageSizeOptions: ["25", "50", "100"],
            showSizeChanger: true,
            locale: {
              items_per_page: "",
            },
          }}
        />
      </CContainer>
    </div>
  );
}

export default WorkingdaysList;
